<template>
  <div class="d-flex">
    <div class="user-info">
      <b-card>
        <div class="wrap-img">
          <b-img
            :src="userImage"
            rounded="circle"
            alt="ảnh người dùng"
          />
        </div>
        <div class="infomation">
          <div class="form-group">
            <label>Họ và tên:</label>
            <div>{{ userData.hoTen }}</div>
          </div>
          <div class="form-group">
            <label>Đơn vị:</label>
            <div>{{ userData.donViTrucThuoc.tenDonVi }}</div>
          </div>
          <div class="form-group">
            <label>Chức vụ:</label>
            <div>{{ userData.chucVu }}</div>
          </div>
        </div>
      </b-card>
    </div>
    <div class="list-ky-so">
      <div class="title-ky-so">
        Danh sách thông tin ký số
      </div>
      <b-card>
        <div class="d-flex">
          <b-button
            size="sm"
            variant="primary"
            class="mb-1"
            @click="($refs.commonModal.showModal()); resetFormKySo()"
          >
            Thêm ký số
          </b-button>
          <p class="d-flex align-items-center ml-2">
            Bạn cần cắm  USB ký số để thực hiện chức năng thêm mới!
          </p>
        </div>
        <good-table
          ref="kySo"
          :columns="columns"
          :rows="danhSachKySo"
          :selectable="false"
          :total="tongSoKySo"
          :custom-status="false"
          @column-filter="layDanhSachKySo"
          @page-change="layDanhSachKySo"
        >
          <template
            slot="custom-filter"
            slot-scope="props"
          >
            <treeselect
              v-if="props.props.column.field === 'loaiKySo'"
              v-model="filterCondition.loaiKySo"
              v-format-v-select
              multiple
              class="vtreeselect-chooser"
              :options="loaiKySo"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder="Chọn loại ký số"
              :clear-on-select="true"
              @input="layDanhSachKySo()"
            />
            <treeselect
              v-else-if="props.props.column.field === 'status'"
              v-model="filterCondition.status"
              v-format-v-select
              class="vtreeselect-chooser"
              :options="[
                {label: 'Còn hiệu lực', id: true},
                {label: 'Hết hiệu lực', id: false},
              ]"
              :limit="0"
              :limit-text="(count) => ` + ${count} lựa chọn`"
              no-options-text="Không có dữ liệu"
              no-results-text="Không có dữ liệu"
              placeholder="Chọn trạng thái"
              :clear-on-select="true"
              @input="layDanhSachKySo()"
            />
          </template>
          <template
            slot="custom-row"
            slot-scope="props"
          >
            <div v-if="props.props.column.field === 'loaiKySo'">
              {{ loaiKySo.find(kyso => kyso.value === props.props.row.loaiKySo).label }}
            </div>
            <div v-else-if="props.props.column.field === 'status'">
              {{ props.props.row.status ? 'Còn hiệu lực' : 'Hết hiệu lực' }}
            </div>
          </template>
        </good-table>
      </b-card>
    </div>
    <common-modal
      ref="commonModal"
      title="Thêm mới ký số"
      @handle-ok="themMoiKySo"
    >
      <b-form>
        <b-row>
          <b-col lg="6">
            <b-form-group
              id="input-group-1"
              label="Loại ký số"
              label-for="loaiKySo"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="loaiKySo"
                rules="required"
              >
                <v-select
                  v-model="formKySo.loaiKySo"
                  :options="loaiKySo"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col lg="6">
            <b-form-group
              label="Số serial"
              label-for="soSerial"
              class="required"
            >
              <validation-provider
                #default="{ errors }"
                name="soSerial"
                rules="required"
              >
                <b-form-input
                  id="soSerial"
                  v-model="formKySo.serialNumber"
                  type="text"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group
          label="Nhà phát hành"
          label-for="nhaPH"
          class="required"
        >
          <validation-provider
            #default="{ errors }"
            name="nhaPH"
            rules="required"
          >
            <b-form-textarea
              id="nhaPH"
              v-model="formKySo.nhaPhatHanh"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Thông tin người ký"
          label-for="ttNguoiKy"
          class="required"
        >
          <validation-provider
            #default="{ errors }"
            name="ttNguoiKy"
            rules="required"
          >
            <b-form-textarea
              id="ttNguoiKy"
              v-model="formKySo.thongTinNguoiKy"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template
        #footer="{ok, cancel}"
      >
        <div>
          <b-button
            size="sm"
            variant="primary"
            class="mr-1"
            @click="layThongTinKySo()"
          >
            Lấy thông tin ký số
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            class="mr-1"
            @click="ok()"
          >
            Thêm ký số
          </b-button>
          <b-button
            size="sm"
            variant="danger"
            @click="cancel()"
          >
            Huỷ
          </b-button>
        </div>
      </template>
    </common-modal>
  </div>
</template>

<script>
import {
  BImg,
  BCard,
  BButton,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormTextarea,
} from 'bootstrap-vue'
import GoodTable from '@/components/GoodTable.vue'
import { COLUMN_KY_SO } from '@/constants/constants'
import END_POINTS from '@/api/endpoints'
import { vgca_get_certinfo } from '@/utils/vgcaplugin'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'AccountInfo',
  components: {
    BImg,
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    GoodTable,
    CommonModal,
    ValidationProvider,
    vSelect,
  },
  data() {
    return {
      required,
      userData: JSON.parse(localStorage.getItem('userInfo')),
      // eslint-disable-next-line global-require
      userImage: require('@/assets/images/svg/profile.9f2af2d1.png'),
      columns: COLUMN_KY_SO.filter(column => column.field !== 'actions'),
      filterCondition: {
        loaiKySo: [],
        serialNumber: '',
        nhaPhatHanh: '',
        thongTinNguoiKy: '',
        username: '',
        status: null,
        pageNumber: 1,
        pageSize: 10,
      },
      danhSachKySo: [],
      tongSoKySo: 0,
      formKySo: {
        loaiKySo: null,
        nhaPhatHanh: '',
        thongTinNguoiKy: '',
        serialNumber: '',
      },
      loaiKySo: [
        { label: 'Cá nhân', value: 1, id: 1 },
        { label: 'Doanh nghiệp, tổ chức', value: 2, id: 2 },
        { label: 'Cá nhân và doanh nghiệp tổ chức', value: 3, id: 3 },
      ],
    }
  },
  created() {
    this.layDanhSachKySo()
  },
  methods: {
    resetFormKySo() {
      this.formKySo = {
        loaiKySo: null,
        nhaPhatHanh: '',
        thongTinNguoiKy: '',
        serialNumber: '',
      }
    },
    layDanhSachKySo(param = {}) {
      let payload = {
        ...this.filterCondition,
        loaiKySo: this.filterCondition.loaiKySo.toString(),
        ...param,
      }
      if (this.$refs.kySo) {
        payload = {
          ...payload,
          ...this.$refs.kySo.getCurrentPage(),
        }
      }
      this.$axios.post(END_POINTS.KY_SO.LAY_DANH_SACH, this.$trimObject({
        ...payload,
      }), false).then(response => {
        if (response.data && response.data.succeeded) {
          this.danhSachKySo = response.data.data
          this.tongSoKySo = response.data.totalCount || 0
          this.$refs.kySo.resetCurrentPage(payload.pageNumber)
        }
      })
    },
    layThongTinKySo() {
      const GetCertInfoCallback = response => {
        if (response && JSON.parse(response).Status === 0) {
          const objData = JSON.parse(response).CertInfo
          this.formKySo.nhaPhatHanh = objData.Issuer
          this.formKySo.thongTinNguoiKy = objData.Subject
          this.formKySo.serialNumber = objData.SerialNumber
        }
      }
      vgca_get_certinfo(GetCertInfoCallback)
    },
    themMoiKySo() {
      this.$axios.post(END_POINTS.KY_SO.THEM_MOI, this.$trimObject({
        ...this.formKySo,
        loaiKySo: this.formKySo.loaiKySo.value,
      })).then(response => {
        if (response.data.succeeded) {
          this.layDanhSachKySo()
          this.$refs.commonModal.hideModal()
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.user-info {
  width: 240px;
  margin-right: 28px;
  margin-top: 30px;
  .wrap-img {
    text-align: center;
    img {
      width: 120px;
      border: 1px solid #0d4470;
      padding: 15px;
    }
  }
  .infomation {
    margin-top: 30px;
    label {
      width: 80px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 600;
    }
    .form-group {
      display: flex;
      div {
        width: 119px;
      }
    }
  }
}
.list-ky-so {
  width: 100%;
  .title-ky-so {
    height: 30px;
  }
}
</style>
